import simpleDDP from "simpleddp";
import ws from "isomorphic-ws";
import { simpleDDPLogin } from "simpleddp-plugin-login";
export function connect() {
    let opts = {
        // endpoint: "wss://dev-admin.kopibajawaflores.id/websocket",
        endpoint: "wss://admin.kopibajawaflores.id/websocket",
        // endpoint: "ws://localhost:3000/websocket",
        SocketConstructor: ws,
        reconnectInterval: 5000,
    };
    return new simpleDDP(opts, [simpleDDPLogin]);
}
